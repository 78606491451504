import {
    HomeIcon,
    BuildingOfficeIcon,
    InboxStackIcon,
    UserCircleIcon,
    UserGroupIcon,
    CurrencyDollarIcon,
    PresentationChartLineIcon,
    ReceiptPercentIcon,
    DocumentTextIcon,
    QueueListIcon,
} from "@heroicons/vue/24/outline/esm/index.js";

import {
    isCurrentPathExact,
    startsWithPath,
} from "~/utils/comparisons/pathComparisons";

export function useAdminNavigation() {
    const currentRoute = useRoute();
    const sidebarOpen = ref(false); // Is this used anywhere? Seem to be using local refs

    const adminNavigation = ref([
        {
            name: "Claims",
            href: "/admin/claims",
            pathRoot: "/admin/claims",
            initial: "C",
            icon: InboxStackIcon,
            current: startsWithPath("/admin/claims", currentRoute.path),
        },
        {
            name: "Clients",
            href: "/admin/clients",
            pathRoot: "/admin/clients",
            initial: "C",
            icon: UserGroupIcon,
            current: startsWithPath("/admin/clients", currentRoute.path),
        },
        {
            name: "Partners",
            href: "/admin/partners",
            pathRoot: "/admin/partners",
            initial: "P",
            icon: BuildingOfficeIcon,
            current: startsWithPath("/admin/partners", currentRoute.path),
        },
        {
            name: "Payments",
            href: "/admin/payments",
            pathRoot: "/admin/payments",
            initial: "P",
            icon: ReceiptPercentIcon,
            current: startsWithPath("/admin/payments", currentRoute.path),
        },
        {
            name: "Payouts",
            href: "/admin/payouts",
            pathRoot: "/admin/payouts",
            initial: "P",
            icon: ReceiptPercentIcon,
            current: startsWithPath("/admin/payouts", currentRoute.path),
        },
        {
            name: "EOBs",
            href: "/admin/eob",
            pathRoot: "/admin/eob",
            initial: "P",
            icon: DocumentTextIcon,
            current: startsWithPath("/admin/eob", currentRoute.path),
        },
        {
            name: "Invoices",
            href: "/admin/invoices",
            pathRoot: "/admin/invoices",
            initial: "P",
            icon: DocumentTextIcon,
            current: startsWithPath("/admin/invoices", currentRoute.path),
        },
        {
            name: "Users",
            href: "/admin/users",
            pathRoot: "/admin/users",
            initial: "U",
            icon: UserCircleIcon,
            current: startsWithPath("/admin/users", currentRoute.path),
        },
        // {
        //     name: "Enter payment/payout",
        //     href: "/admin/payments/add/payment",
        //     pathRoot: "/admin/payments/",
        //     initial: "P",
        //     icon: CurrencyDollarIcon,
        //     current: startsWithPath("/admin/payments", currentRoute.path),
        // },
        {
            name: "Transactions",
            href: "/admin/transactions",
            pathRoot: "/admin/transactions",
            initial: "P",
            icon: CurrencyDollarIcon,
            current: startsWithPath("/admin/transactions", currentRoute.path),
        },
        {
            name: "Insurance Companies",
            href: "/admin/insurancecompanies",
            pathRoot: "/admin/insurancecompanies",
            initial: "P",
            icon: CurrencyDollarIcon,
            current: startsWithPath(
                "/admin/insurancecompanies",
                currentRoute.path
            ),
        },
        {
            name: "Claims by Insurance",
            href: "/admin/claimsbyinsurance",
            pathRoot: "/admin/claimsbyinsurance",
            initial: "P",
            icon: PresentationChartLineIcon,
            current: startsWithPath(
                "/admin/claimsbyinsurance",
                currentRoute.path
            ),
        },
    ]);

    const adminHomeNavigation = ref([
        {
            name: "Home",
            href: "/admin/events",
            pathRoot: "/admin/events",
            initial: "P",
            icon: HomeIcon,
            current: startsWithPath("/admin/events", currentRoute.path),
        },
        // {
        //     name: "Home",
        //     href: "/admin",
        //     pathRoot: "/admin",
        //     initial: "A",
        //     icon: HomeIcon,
        //     current: isCurrentPathExact("/admin", currentRoute.path),
        // }
    ])

    const adminCounterpartiesNavigation = ref([
        {
            name: "Clients",
            href: "/admin/clients",
            pathRoot: "/admin/clients",
            initial: "C",
            icon: UserGroupIcon,
            current: startsWithPath("/admin/clients", currentRoute.path),
        },
        {
            name: "Partners",
            href: "/admin/partners",
            pathRoot: "/admin/partners",
            initial: "P",
            icon: BuildingOfficeIcon,
            current: startsWithPath("/admin/partners", currentRoute.path),
        },
        {
            name: "Insurance Companies",
            href: "/admin/insurancecompanies",
            pathRoot: "/admin/insurancecompanies",
            initial: "P",
            icon: CurrencyDollarIcon,
            current: startsWithPath(
                "/admin/insurancecompanies",
                currentRoute.path
            ),
        },
    ]);

    const adminClaimLifecycleNavigation = ref([
        {
            name: "Invoices",
            href: "/admin/invoices",
            pathRoot: "/admin/invoices",
            initial: "P",
            icon: DocumentTextIcon,
            current: startsWithPath("/admin/invoices", currentRoute.path),
        },
        {
            name: "Claims",
            href: "/admin/claims",
            pathRoot: "/admin/claims",
            initial: "C",
            icon: InboxStackIcon,
            current: startsWithPath("/admin/claims", currentRoute.path),
        },
        {
            name: "Transactions",
            href: "/admin/transactions",
            pathRoot: "/admin/transactions",
            initial: "P",
            icon: CurrencyDollarIcon,
            current: startsWithPath("/admin/transactions", currentRoute.path),
        },
        {
            name: "Payments",
            href: "/admin/payments",
            pathRoot: "/admin/payments",
            initial: "P",
            icon: ReceiptPercentIcon,
            current: startsWithPath("/admin/payments", currentRoute.path),
        },
        {
            name: "Payout Items",
            href: "/admin/payout-items",
            pathRoot: "/admin/payout-items",
            initial: "P",
            icon: QueueListIcon,
            current: startsWithPath("/admin/payout-items", currentRoute.path),
        },
        {
            name: "Payouts",
            href: "/admin/payouts",
            pathRoot: "/admin/payouts",
            initial: "P",
            icon: ReceiptPercentIcon,
            current: startsWithPath("/admin/payouts", currentRoute.path),
        },
        {
            name: "EOBs",
            href: "/admin/eob",
            pathRoot: "/admin/eob",
            initial: "P",
            icon: DocumentTextIcon,
            current: startsWithPath("/admin/eob", currentRoute.path),
        },
    ]);

    const adminAnalyticsNavigation = ref([
        {
            name: "Dashboard",
            href: "/admin",
            pathRoot: "/admin",
            initial: "A",
            icon: PresentationChartLineIcon,
            current: isCurrentPathExact("/admin", currentRoute.path),
        },
        // {
        //     name: "User Activity",
        //     href: "/admin/events",
        //     pathRoot: "/admin/events",
        //     initial: "P",
        //     icon: PresentationChartLineIcon,
        //     current: startsWithPath("/admin/events", currentRoute.path),
        // },
        {
            name: "Claims by Insurance",
            href: "/admin/claimsbyinsurance",
            pathRoot: "/admin/claimsbyinsurance",
            initial: "P",
            icon: PresentationChartLineIcon,
            current: startsWithPath(
                "/admin/claimsbyinsurance",
                currentRoute.path
            ),
        },
    ]);

    const adminConfigurationNavigation = ref([
        {
            name: "Users",
            href: "/admin/users",
            pathRoot: "/admin/users",
            initial: "U",
            icon: UserCircleIcon,
            current: startsWithPath("/admin/users", currentRoute.path),
        },
    ]);

    const adminPaymentEntryTabsMenuData = ref([
        {
            name: "Create payment (incoming)",
            href: "/admin/payments/add/payment",
            current: startsWithPath(
                "/admin/payments/add/payment",
                currentRoute.path
            ),
        },
        {
            name: "Create payout (outgoing)",
            href: "/admin/payments/add/payout",
            current: startsWithPath(
                "/admin/payments/add/payout",
                currentRoute.path
            ),
        },
    ]);

    watchEffect(() => {
        adminHomeNavigation.value.forEach((item) => {
            if (item.name == "Dashboard") {
                item.current = isCurrentPathExact(
                    item.pathRoot,
                    currentRoute.path
                );
            } else {
                item.current = startsWithPath(item.pathRoot, currentRoute.path);
            }
        });
    });
    watchEffect(() => {
        adminCounterpartiesNavigation.value.forEach((item) => {
            if (item.name == "Dashboard") {
                item.current = isCurrentPathExact(
                    item.pathRoot,
                    currentRoute.path
                );
            } else {
                item.current = startsWithPath(item.pathRoot, currentRoute.path);
            }
        });
    });
    watchEffect(() => {
        adminClaimLifecycleNavigation.value.forEach((item) => {
            if (item.name == "Dashboard") {
                item.current = isCurrentPathExact(
                    item.pathRoot,
                    currentRoute.path
                );
            } else {
                item.current = startsWithPath(item.pathRoot, currentRoute.path);
            }
        });
    });
    watchEffect(() => {
        adminAnalyticsNavigation.value.forEach((item) => {
            if (item.name == "Dashboard") {
                item.current = isCurrentPathExact(
                    item.pathRoot,
                    currentRoute.path
                );
            } else {
                item.current = startsWithPath(item.pathRoot, currentRoute.path);
            }
        });
    });
    watchEffect(() => {
        adminConfigurationNavigation.value.forEach((item) => {
            if (item.name == "Dashboard") {
                item.current = isCurrentPathExact(
                    item.pathRoot,
                    currentRoute.path
                );
            } else {
                item.current = startsWithPath(item.pathRoot, currentRoute.path);
            }
        });
    });

    watchEffect(() => {
        adminPaymentEntryTabsMenuData.value.forEach((item) => {
            item.current = isCurrentPathExact(item.href, currentRoute.path);
        });
    });

    return {
        adminNavigation,
        adminHomeNavigation,
        adminCounterpartiesNavigation,
        adminClaimLifecycleNavigation,
        adminAnalyticsNavigation,
        adminConfigurationNavigation,
        adminPaymentEntryTabsMenuData,
        sidebarOpen,
    };
}
